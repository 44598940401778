<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-05-28 09:59:27
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\county.vue
-->
<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="left">
          <title2 name="志愿服务概况" style="margin: 0 0 24px;"></title2>
          <div class="sevTitle">
            <div class="box1">
              <span>服务次数(次)</span>
              <span class="num">{{service.totalNum}}</span>
            </div>
            <div class="box2">
              <span>服务时长(小时)</span>
              <span class="num">{{service.totalHour}}</span>
            </div>
            <div class="box3">
              <span>投入(元)</span>
              <span class="num">{{service.totalInput}}</span>
            </div>
          </div>
          <title2 name="农资下乡" style="margin: 30px 0 24px;"></title2>
          <ul class="garden-list">
            <li>
              <span class="title">菜园</span>
              <span class="title">农资下乡重量</span>
              <span class="title">次数</span>
              <span class="title">折算金额</span>
              <span class="title">服务单位数量</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body income">
            <vue-seamless-scroll :data="infomationNzxxCountVOList" :class-option="defaultOption">
              <li
                v-for="(item, index) in infomationNzxxCountVOList"
                :key="index"
              >
                <span>{{ item.gardenName }}</span>
                <span>{{ item.nzNameCount }}斤</span>
                <span>{{ item.num }}次</span>
                <span>{{ item.nzAmounts }}元</span>
                <span>{{ item.serviceUnitCount }}家</span>
              </li>
            </vue-seamless-scroll>
          </ul>
          <title2 name="田间管理" style="margin: 30px 0 24px;"></title2>
          <div class="shell2">
            <div class="vegetable2">
            <vue-seamless-scroll :data="infomationCroplandVOList" :class-option="defaultOption">
              
                <div class="box" v-for="(item, index) in infomationCroplandVOList" :key="index">
                  <div class="title">{{item.gardenName}}</div>
                  <div class="heng" />
                  <div class="lists">
                    <div>
                      <span class="text">服务内容</span
                      ><span class="money">{{item.serviceContent}}</span>
                    </div>
                    <div>
                      <span class="text">折算金额</span
                      ><span class="money">{{item.serviceInput}} 元</span>
                    </div>
                    <div>
                      <span class="text">服务单位</span
                      ><span class="money">{{item.serviceUnits}}</span>
                    </div>
                    <div>
                      <span class="text">时长</span
                      ><span class="money">{{item.serviceHours}} 小时</span>
                    </div>
                  </div>
                </div>
              
            </vue-seamless-scroll>
            </div>
          </div>
        </div>

        <div class="right">
          <title2 name="镇、街菜园服务次数排行榜"></title2>
          <el-table :data="serviceCountList" style="width: 100%">
            <el-table-column prop="date" label="排名" width="94">
              <template slot-scope="scope">
                <div
                  :class="{
                    exclusion: true,
                    exclusion1: scope.$index == 0,
                    exclusion2: scope.$index == 1,
                    exclusion3: scope.$index == 2,
                  }"
                >
                  {{ scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="镇、街道" width="208" />
            <el-table-column prop="value" label="服务次数" width="112">
              <template slot-scope="scope">
                {{scope.row.value}} 次
              </template>
            </el-table-column>
            <el-table-column prop="address" label="">
              <template slot-scope="scope">
                <el-progress
                  :stroke-width="16"
                  :percentage="Number((scope.row.value*100/serviceCountNum).toFixed(0))"
                  :show-text="false"
                  ></el-progress
                >
              </template>
            </el-table-column>
          </el-table>
          <title2 name="志愿服务事项清单" style="margin: 30px 0 24px;"></title2>
          <div class="shellout">
          <div class="shell">
            <vue-seamless-scroll :data="serviceList" :class-option="defaultOption2">
              <div class="vegetable">
                <div class="box" v-for="(item, index) in serviceList" :key="index">
                  <div class="title">{{item.gardenName}}</div>
                  <div class="heng" />
                  <div class="lists" v-if="item.serviceType === 'TO_COUNTRYSIDE'">
                    <div>
                      <span class="text">事项</span
                      ><span class="money">{{item.serviceTypeLabel}}</span>
                    </div>
                    <div>
                      <span class="text">农资</span
                      ><span class="money">{{item.nzxx.nzName}}</span>
                    </div>
                    <div>
                      <span class="text">农资总额</span
                      ><span class="money">{{item.nzxx.nzAmount}} 元</span>
                    </div>
                    <div>
                      <span class="text">时间</span
                      ><span class="money">{{(item.createTime).substr(0, 10)}}</span>
                    </div>
                  </div>
                  <div class="lists" v-if="item.serviceType === 'TECH_GUIDE_SERVICE' || item.serviceType === 'MNG_VOLUNTEER_SERVICE'">
                    <div>
                      <span class="text">事项</span
                      ><span class="money">{{item.serviceTypeLabel}}</span>
                    </div>
                    <div>
                      <span class="text">时长</span
                      ><span class="money">{{item.serviceHours}} 小时</span>
                    </div>
                    <div>
                      <span class="text">投入</span
                      ><span class="money">{{item.serviceInput}} 元</span>
                    </div>
                    <div>
                      <span class="text">时间</span
                      ><span class="money">{{item.createTime.substr(0, 10)}}</span>
                    </div>
                  </div>
                  <div class="lists" v-if="item.serviceType === 'SUBSCRIPTION_BY_LOVE'">
                    <div>
                      <span class="text">事项</span
                      ><span class="money">{{item.serviceTypeLabel}}</span>
                    </div>
                    <div>
                      <span class="text">总量</span
                      ><span class="money">{{item.subVolume}} 斤</span>
                    </div>
                    <div>
                      <span class="text">金额</span
                      ><span class="money">{{item.subAmount}} 元</span>
                    </div>
                    <div>
                      <span class="text">时间</span
                      ><span class="money">{{item.createTime.substr(0, 10)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "county",
  data() {
    return {
      loading: true,
      serviceCountList: [],
      serviceCountNum: null,
      service: {},
      infomationNzxxCountVOList: [],
      infomationCroplandVOList: [],
      serviceList: [],
    };
  },
  computed: {
    defaultOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    defaultOption2() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$api.bigdata.service("330481").then((res) => {
        res.data.serviceCountList.map(item => {
          this.serviceCountList.push({name: Object.keys(item)[0], value: Object.values(item)[0]})
          this.serviceCountNum += Object.values(item)[0]
        })
        this.service = res.data.service
        this.infomationNzxxCountVOList = res.data.infomationNzxxCountVOList
        this.infomationCroplandVOList = res.data.infomationCroplandVOList
        res.data.serviceList.map(item => {
          if (item.serviceType === 'SUBSCRIPTION_BY_LOVE') {
            item.subAmount = 0
            item.subVolume = 0
            item.list.map(it => {
              item.subAmount += Number(it.subAmount)
              item.subVolume += Number(it.subVolume)
            })
          }
        })
        this.serviceList = res.data.serviceList

        this.loading = false
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sevTitle {
  display: flex;
  justify-content: space-between;
  > div {
    width: 168px;
    height: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    span {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #F2F2F2;
      letter-spacing: 1px;
    }
    .num {
      font-size: 30px;
      font-family: DIN Medium-Regular, DIN Medium;
      color: #FFFFFF;
      letter-spacing: 1px;
      margin-top: 8px;
    }
  }
  .box1 {
    background: url('../assets/images/菜园服务／志愿服务／服务次数bg@2x.png') no-repeat;
    background-size: 100%;
  }
  .box2 {
    background: url('../assets/images/菜园服／志愿服务／服务时长bg@2x.png') no-repeat;
    background-size: 100%;
  }
  .box3 {
    background: url('../assets/images/菜园服务／志愿服务／投入bg@2x.png') no-repeat;
    background-size: 100%;
  }
}
.exclusion {
  width: 24px;
  height: 24px;
  background: #0084c7;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exclusion1 {
  background: #fe6b00;
}
.exclusion2 {
  background: #fec700;
}
.exclusion3 {
  background: #64c1b6;
}
::v-deep .el-progress-bar__outer {
  background: rgba(28, 93, 180, 0.6);
}
::v-deep .el-progress-bar__inner {
  background: linear-gradient(90deg, #F7971E 0%, #FFD200 100%);
}
::v-deep .el-table--fit {
  background: none;
}
::v-deep .el-table tr {
  background-color: transparent;
}
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
::v-deep .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
::v-deep .el-table td {
  border-bottom: 0px solid #dfe6ec !important;
}
::v-deep .el-table .cell {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
}
::v-deep .el-table td.el-table__cell div {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.shellout {
  width: 1267px;
  height: 254px;
  overflow: hidden;
  background: url("../assets/images/各菜园bg@2x.png") no-repeat;
  background-size: 100%;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: center;
}
.shell {
  width: 1200px;
  height: 254px;
  overflow: hidden;
  padding: 0 40px;
}
.vegetable {
  width: 100%;
  height: 254px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  .box {
    width: 301px;
    height: 201px;
    background: url("../assets/images/菜园服务／志愿服务事项卡片／bg@2x.png") no-repeat;
    background-size: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    .title {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
    }
    .heng {
      width: 16px;
      height: 4px;
      background: #00aaff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 8px 0 16px;
    }
    .lists {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      div {
        display: flex;
        justify-content: space-between;
      }
      .text {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .money {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
        width: 150px;
        text-align: right;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
  }
}
.shell2 {
  width: 530px;
  height: 210px;
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.vegetable2 {
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box {
    width: 530px;
    height: 187px;
    background: url("../assets/images/菜园服务／田间管理卡片／bg@2x.png") no-repeat;
    background-size: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    .title {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
    }
    .heng {
      width: 16px;
      height: 4px;
      background: #00aaff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 8px 0 16px;
    }
    .lists {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      div {
        display: flex;
        justify-content: space-between;
      }
      .text {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .money {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
        width: 300px;
        text-align: right;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
  }
}
.main {
  .content {
    display: flex;
    padding: 24px 46px;
    .left {
      position: relative;
      flex: 1;
      width: 530px;
      .year-total {
        margin: 30px 0;
        width: 239px;
        height: 427px;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/year.png);
        background-size: 100% 100%;
        .header {
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 24px;
        }
        .list {
          .round-rotate {
            margin: 10px 0;
          }
        }
      }
      .county-total {
        font-size: 18px;
        margin-top: 30px;
        border-top: 1px solid rgba(0, 149, 255, 0.5);
        border-left: 1px solid rgba(0, 149, 255, 0.5);
        table {
          width: 100%;
          tr {
            &.area {
              td {
                background: rgba(0, 63, 152, 1);
                text-shadow: 0 0 2px #007ffc;
              }
            }
            &.num {
              td {
                &:nth-child(odd) {
                  .line {
                    background-image: linear-gradient(
                      0deg,
                      rgba(247, 181, 0, 0) 0%,
                      rgba(247, 181, 0, 0.75) 100%
                    );
                    border-color: rgba(#f7b500, 0.7);
                  }
                }
              }
            }
            td {
              text-align: center;
              vertical-align: bottom;
              padding: 5px 0;
              line-height: 42px;
              border-right: 1px solid rgba(0, 149, 255, 0.5);
              border-bottom: 1px solid rgba(0, 149, 255, 0.5);
              background: rgba(0, 63, 152, 0.4);
              &.header {
                color: #00aaff;
              }
            }
          }
        }
        .bar {
          position: relative;
          height: 115px;
          width: 70%;
          margin: 0 auto;
          .text {
            position: absolute;
            width: 100%;
            bottom: 0;
          }
          .line {
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 50%;
            background-image: linear-gradient(
              0deg,
              rgba(0, 199, 255, 0) 0%,
              rgba(0, 149, 255, 0.75) 100%
            );
            border: 2px solid rgba(#00afff, 0.7);
            border-bottom: none;
            transition: height 0.3s;
          }
        }
      }
      .map {
        position: absolute;
        top: 80px;
        left: 250px;
        right: 0;
        bottom: 250px;
      }
      .light-level {
        position: absolute;
        left: 50%;
        transform: translateX(-25%);
        top: 540px;
        font-size: 0;
        text-align: center;
        li {
          margin: 0 8px;
          display: inline-block;
          cursor: pointer;
          width: 160px;
          height: 56px;
          line-height: 56px;
          background-size: 100% 100%;
          &:nth-child(1) {
            background-image: url(../assets/images/green_bg.png);
          }
          &:nth-child(2) {
            background-image: url(../assets/images/yellow_bg.png);
          }
          &:nth-child(3) {
            background-image: url(../assets/images/red_bg.png);
          }
          img {
            display: inline-block;
            width: 40px;
            vertical-align: top;
            margin-top: 7px;
          }
          span {
            display: inline-block;
            vertical-align: top;
            &.num {
              font-size: 18px;
              font-style: italic;
              margin: 0 2px 0 6px;
            }
            &.unit {
              font-size: 14px;
            }
          }
        }
      }
    }

    .right {
      width: 1267px;
      margin-left: 35px;
      ::v-deep .el-carousel__container {
        height: 234px;
        margin: 21px 0;
      }
      .plant-list {
        overflow: hidden;
        li {
          display: flex;
          padding: 0 16px;
          height: 54px;
          line-height: 54px;
          font-size: 18px;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/plant.png);
          background-size: 100% 100%;
          & + li {
            margin-top: 6px;
          }
          .name {
            width: 130px;
            text-shadow: 0 0 2px #007ffc;
          }
          .num {
            width: 110px;
            color: #00aaff;
          }
          .bar {
            flex: 1;
            .line {
              margin-top: 13px;
              height: 30px;
              width: 50%;
              background-color: #007ffc;
            }
          }
        }
      }
      
    }
    .garden-list {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          height: 250px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          display: flex;
          &:nth-child(even) {
            background: rgba(0, 63, 152, 0.3);
          }
          span {
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            letter-spacing: 1px;
            display: inline-block;
            flex: 1;
            font-size: 16px;
            text-align: center;
            line-height: 46px;
            color: #FFFFFF;
          }
          .title {
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
  }
}
</style>